@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body,
a {
	font-family: 'Poppins', 'Prompt', sans-serif;
	::-webkit-scrollbar {
		background-color: transparent;
		width: 5px;
	}
	* {
		scrollbar-width: thin;
		scrollbar-color: lightgray;
	}
	*::-webkit-scrollbar-track {
		background-color: transparent;
	}
	*::-webkit-scrollbar-thumb {
		background-color: lightgray ;
		border-radius: 20px;
		border: 3px solid lightgray;
	}
}

.material-icons-outlined {
	font-size: 24px !important;
	color: #808080;
	display: flex;
}

.IconRecord {
	font-size: 14px !important;
	padding-left: 20px;
}

.IconMoreHoriz {
	font-size: 22px !important;
}

// Sider

.hideOnMobile {
	display: none;
}
@media only screen and (min-width: 768px) {
	.hideOnMobile {
		display: block;
	}
}

.hideOnDesktop {
	display: block;
}
@media only screen and (min-width: 768px) {
	.hideOnDesktop {
		display: none;
	}
}

.ant-drawer-body {
	overflow: hidden;
}

// MenuRight Horizontal
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
	border-bottom: 4px solid #ffaf02 !important;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
	color: #333333 !important;
}

// input
.ant-input {
	font-size: 15px !important;
	font-weight: normal !important;
	color: #333 !important;
	border-radius: 5px !important;
	border: solid 0.8px #d8d8d8 !important;
	&:focus {
		border: solid 0.8px #b3b3b3 !important;
		outline: 0;
		-webkit-box-shadow: 0 0 2px 2px #f2f2f2 !important;
		box-shadow: 0 0 2px 2px #f2f2f2 !important;
	}
}

.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
	background-color: #fff;
	border-color: #f64e60 !important;
	&:focus {
		box-shadow: 0 0 2px 2px rgba(246, 78, 96, 0.14) !important;
	}
}

// input pass
.ant-input-affix-wrapper {
	border: solid 0.8px #d8d8d8 !important;
}

.ant-input-affix-wrapper-focused {
	border: solid 0.8px #b3b3b3 !important;
	-webkit-box-shadow: 0 0 2px 2px #f2f2f2 !important;
	box-shadow: 0 0 2px 2px #f2f2f2 !important;
}

// <Button />
.ant-btn {
	color: #333 !important;
	border: solid 1px #d8d8d8 !important;
	background-color: #fff !important;
	&:hover {
		border: solid 1px #d8d8d8 !important;
		background-color: #f2f2f2 !important;
		box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.16) !important;
	}
	&:focus {
		border: solid 1px #d8d8d8 !important;
		background-color: #f2f2f2 !important;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05) !important;
	}
}

// <Button disabled />
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
	opacity: 0.8 !important;
	color: #b3b3b3 !important;
	background-color: #d8d8d8 !important;
}

// <Button type="primary" />
.ant-btn-primary {
	border: solid 1px #ffaf02 !important;
	background-color: #ffaf02 !important;
	&:hover {
		box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.16) !important;
		border: solid 1px #e89f00 !important;
		background-color: #f2a600 !important;
	}
	&:focus {
		border: solid 1px #e89f00 !important;
		background-color: #f2a600 !important;
		box-shadow: 0 0 2px 2px rgba(255, 175, 2, 0.24) !important;
	}
}

// <Button type="primary" disabled/>
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
	opacity: 0.6 !important;
	color: #333 !important;
	box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.16) !important;
	border: solid 1px #e89f00 !important;
	background-color: #f2a600 !important;
}

// <Button danger />
.ant-btn-dangerous {
	color: #fff !important;
	background-color: #f64e60 !important;
	border-color: #f64e60 !important;
	&:hover {
		color: #fff !important;
		background-color: #f64e60 !important;
		border-color: #f64e60 !important;
		box-shadow: unset !important;
	}
	&:focus {
		color: #fff !important;
		background-color: #f64e60 !important;
		border-color: #f64e60 !important;
		box-shadow: unset !important;
	}
}

// <Button type="text" />
.ant-btn-text {
	color: #808080 !important;
	border-color: transparent !important;

	&:hover {
		border-color: transparent !important;
		background-color: #f2f2f2 !important;
		box-shadow: unset !important;
	}
	&:focus {
		border-color: transparent !important;
		background-color: #f2f2f2 !important;
		box-shadow: unset !important;
	}
}

// <Button type="link" />
.ant-btn-link {
	color: #ffaf02 !important;
	border-color: transparent !important;
	background-color: unset !important;
	&:hover {
		border-color: transparent !important;
		background-color: unset !important;
		box-shadow: unset !important;
	}
	&:focus {
		border-color: transparent !important;
		background-color: unset !important;
		box-shadow: unset !important;
	}
}

[ant-click-animating-without-extra-node]:after {
	animation: 0s !important;
	border-color: transparent !important;
}

// ant select
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-radius: 5px !important;
	border-color: #d8d8d8 !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: #d8d8d8 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	box-shadow: 0 0 2px 2px #f2f2f2 !important;
	border-color: #d8d8d8 !important;
}

// ant select dropdown

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background-color: #fff !important;
	font-weight: normal !important;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: #fff8e8 !important;
}
